import { PtWorkflowTypes } from '../../../custom-types';
import { HouseCallState } from './house-calls';
import { PelvicTrainerOutreachState } from './pelvic-trainer-outreach';
import { VideoVisitsState } from './video-visit';
export enum PtDashboardFilters {
  All = 'All',
  VideoVisit = 'Video visit',
  VideoVisitIncomplete = 'Video visit : Incomplete Video Visits',
  VideoVisitUpcoming = 'Video visit : Upcoming Video Visits',
  HouseCall = 'House call',
  HouseCallUpcoming = 'House call : Upcoming House Calls',
  HouseCallIncomplete = 'House call : Open House Calls',
  UnreadMessage = 'Unreads',
  UnreadMessageNoResponse = 'Unread messages (No Response)',
  HealthLogNotes = 'Health log - notes',
  HealthLogNote = 'Health log note',
  HealthLogNoResponse = 'Health log - notes (No Response)',
  PainUptick = 'Health log - pain uptick',
  ExpiringCarePlans = 'Expiring care plans',
  DischargeAndClosePlan = 'Discharge and close plan',
  Completed = 'Completed today',
  EtModification = 'ET modification',
  ClinicalEscalation = 'Clinical escalation',
  MedicalEmergencyFollowUp = 'Medical emergency follow-up',
  SurgeryTrend = 'Surgery trend',
  FirstWeekEnsoCheckIn = 'Enso first week checkin',
  AqRedFlags = 'AQ red flags',
  PtMemberAlertReminder = 'Alert reminders',
  ActionPlanReview = 'Review and recalibrate',
  SetupActionPlan = 'Setup action plan',
  Messages = 'Messages',
  ModularIamUnreadMessages = 'HLN',
  AllTypes = 'All Types',
  IndicationUpdate = 'Indication update',
  PelvicTrainerOutreach = 'Pelvic Trainer check-in',
}
export const defaultPtWorkflows = [PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.HealthLog, PtWorkflowTypes.PainUptick, PtWorkflowTypes.Acute, PtWorkflowTypes.Chronic, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute, PtWorkflowTypes.Expiring, PtWorkflowTypes.Closing, PtWorkflowTypes.HouseCall, PtWorkflowTypes.MemberPainUptick, PtWorkflowTypes.ModularIamUnreadMessages, PtWorkflowTypes.SurgeryTrend, PtWorkflowTypes.PelvicTrainerOutreach, PtWorkflowTypes.FirstWeekEnsoCheckIn, PtWorkflowTypes.IndicationUpdate, PtWorkflowTypes.EtModification, PtWorkflowTypes.ClinicalEscalation, PtWorkflowTypes.MedicalEmergencyFollowUp, PtWorkflowTypes.PtMemberAlertReminder, PtWorkflowTypes.AqRedFlags];
export const videoVisitFilters = [PtDashboardFilters.VideoVisit, PtDashboardFilters.VideoVisitIncomplete, PtDashboardFilters.VideoVisitUpcoming];
export const houseCallFilters = [PtDashboardFilters.HouseCall, PtDashboardFilters.HouseCallIncomplete, PtDashboardFilters.HouseCallUpcoming];
export const healthLogFilters = [PtDashboardFilters.HealthLogNotes, PtDashboardFilters.HealthLogNoResponse];
export const unreadMessageFilters = [PtDashboardFilters.UnreadMessage, PtDashboardFilters.UnreadMessageNoResponse];
export const alertReminderFilters = [PtDashboardFilters.PtMemberAlertReminder];
export enum CommonPtState {
  InactiveMember = 'inactive-member',
  AcuteComplete = 'acute-complete',
  AcuteDismiss = 'acute-dismiss',
  VideoVisitComplete = 'complete',
  Dismiss = 'dismiss',
  AwaitingNew = 'awaiting-new',
  Complete = 'complete',
  Closed = 'closed',
  Archive = 'archive',
}
export const commonPtExcludedStates = [CommonPtState.AcuteComplete, VideoVisitsState.CancelVideoVisit, HouseCallState.CancelHouseCall, PelvicTrainerOutreachState.PelvicTrainerOrdered, CommonPtState.VideoVisitComplete, CommonPtState.InactiveMember, CommonPtState.Dismiss, CommonPtState.AwaitingNew, CommonPtState.Closed, CommonPtState.Archive];
export const commonPtCompletedStates: CommonPtState[] = [CommonPtState.Dismiss, CommonPtState.AcuteDismiss, CommonPtState.AcuteComplete, CommonPtState.VideoVisitComplete, CommonPtState.Closed];
export enum PtVideoVisits {
  Acute = 'acute-video-visits',
  Chronic = 'chronic-video-visit',
  Surgery = 'surgery-video-visit',
  FifteenMinute = '15-min-video-visits',
}
export const ptCarePlanFilters = [PtDashboardFilters.ExpiringCarePlans, PtDashboardFilters.DischargeAndClosePlan];