const MAX_FILE_SIZE_IN_MB = 20;
const MAX_FILE_SIZE_IN_MB_OLD = 0.5;
const MAX_FILE_UPLOAD_LIMIT = 10;
export const ABORT_UPLOAD_TIMEOUT = 30 * 1000; // 30 seconds

export const USER_FEEDBACK = {
  MAX_CHARACTER_LIMIT: 250,
  PRIORITY_TITLE: 'Priority (optional)',
  PRIORITY_ID: 'priority-select',
  BUG_STRING: 'BUG',
  REQUEST_STRING: 'ENHANCEMENT',
  USER_ID: 'User ID',
  PROGRAM: 'Program',
  INDICATION_LABEL: 'Indication',
  MULTIPLE_USER_ID: 'User IDs (comma separated)',
  SUCCESS_ALERT_PREFIX: 'Thank you for your feedback! Our team will review your ticket (',
  SUCCESS_ALERT_SUFFIX: ') shortly',
  SUCCESS_TITLE: 'Feedback submitted',
  FAILURE_TITLE: 'Feedback not submitted',
  FAILURE_MESSAGE: 'We were unable to submit the feedback due to unstable internet',
  ERROR_TEXT_VALUE: 'Please fill the required field',
  DATE_ERROR: 'Please enter a valid date',
  FEEDBACK_NETWORK_ERROR: 'We were unable to submit the feedback due to unstable internet',
  FEEDBACK_SUBMIT_ERROR: 'We were unable to submit the feedback, please try again',
  FILE_LIMIT_ERROR: `Maximum ${MAX_FILE_UPLOAD_LIMIT} files can be uploaded`,
  CHARACTER_LIMIT_ERROR: 'Title should be less than 250 characters',
  DIALOG_TEXTS: {
    FROM_TITLE: 'Share feedback',
    ABOUT_YOU: 'About you',
    MEMBER_UUID_TITLE: 'Member UUID(s) impacted (optional)',
    MEMBER_UUID_PLACEHOLDER: 'Member UUID',
    MEMBER_ID_TITLE: 'User ID(s):',
    MEMBER_IMPACTED_TITLE: 'Member(s) impacted (optional)',
    MEMBER_IMPACTED: {
      ALL: 'All',
      SINGLE: 'Single',
      MULTIPLE: 'Multiple'
    },
    ROLE: 'What is your role?',
    SUPPORT: 'What kind of support do you need?',
    MEMBER_ID_PLACEHOLDER: '12345,123456,1234567',
    BUG: {
      TITLE: 'Summarise the issue',
      TITLE_PLACEHOLDER: 'Provide a brief title or summary of the issue.',
      DESCRIPTION: 'Describe the issue, including any steps to reproduce or additional context',
      DESCRIPTION_PLACEHOLDER: 'Describe the issue including any steps to reproduce or additional context',
      PRIORITY: 'Priority (optional)',
      DATE_OF_ISSUE: 'Date of issue (optional)'
    },
    ENHANCEMENT: {
      TITLE: 'Summarise the request',
      TITLE_PLACEHOLDER: 'Provide a brief title or summary of the request',
      DESCRIPTION: 'Details of the request',
      DESCRIPTION_PLACEHOLDER: 'Describe request in detail and why is it important'
    },
    TITLE_BUG_REQUEST_QUES: 'Describe issue ',
    ATTACHMENT_TEXT: 'Attachment (optional)'
  },
  MEMBER_ID_PREFIX: 'Member UUID(s): ',
  UPLOAD_FILES_TEXT: 'Upload files',
  SUBMIT_FORM_BUTTON_TEXT: 'Submit',
  SUBMIT_ANOTHER_BUTTON_TEXT: 'Submit Another',
  RETRY_BUTTON_TEXT: 'Retry',
  DISCLAIMER_TEXT: 'Avoid uploading anything containing PHI data',
  TEST_IDS: {
    DELETE_BUTTON: 'delete-file-',
    FILE_INPUT: 'file-input',
    CLOSE_BUTTON: 'close-button',
    TICKET_LINK: 'ticket-link',
    REQUEST_TYPE_SELECT: 'request-type-select',
    REQUEST_SUB_TYPE_SELECT: 'request-sub-type-select',
    REQUEST_DETAIL_SELECT: 'request-detail-select'
  },
  MAX_NUMBER_OF_FILES_UPLOAD: MAX_FILE_UPLOAD_LIMIT,
  MAX_FILE_SIZE_IN_MB,
  MAX_FILE_SIZE_IN_MB_OLD,
  LOADER_TEXT: {
    CREATING_TICKET: 'Creating ticket...',
    UPLOADING_FILES: 'Uploading attachments'
  },
  FRESHDESK_TICKET_URL: process.env.FRESHDESK_URL + '/a/tickets/',
  CTT_BFF_CREATE_TICKET_PATH: '/freshdesk-feedback/create-ticket',
  CTT_BFF_UPLOAD_ATTACHMENT_PATH_OLD: '/freshdesk-feedback/upload-attachment',
  CTT_BFF_UPLOAD_ATTACHMENT_PATH: '/freshdesk-feedback/upload-attachments-to-freshdesk',
  CTT_BFF_GET_PRE_SIGNED_URLS_FOR_UPLOAD_PATH: '/freshdesk-feedback/get-presigned-urls-for-upload',
  LOCAL_STORAGE_KEY_NAMES: {
    FRESHDESK_TICKET_CUSTOM_FIELDS: 'freshdeskTicketCustomFields',
    FRESHDESK_TICKET_FIELDS: 'freshdeskTicketDetails'
  },
  MAX_FILE_SIZE_BYTES: MAX_FILE_SIZE_IN_MB * 1024 * 1024,
  UPLOAD_ERROR_MESSAGE: `Please upload a file smaller than ${MAX_FILE_SIZE_IN_MB} MB`,
  UPLOAD_ERROR_MESSAGE_OLD: `Please upload a file smaller than ${MAX_FILE_SIZE_IN_MB_OLD} MB`,
  KNOWLEDGE_BASE_URL: 'https://hingehealth6336.freshdesk.com/support/home',
  ET_PERSONALIZATION_OPTION: 'ET Personalization Engine (ETPE)',
  CTT_CTM_OPTION: 'CTT/CTM and Admin Panel'
};
const CF_DIRECT_SUPPORT_PILOT_MANAGERS_PS_REQUEST = (['User Passed Away', 'User Outside US', 'Active Workers Compensation', 'User Reassignment', 'Request for Rule Exception', 'Feedback, Questions, and Complaints'] as const);
const CF_DETAIL_USER_OUTSIDE_US = (['Confirmed Outside US >30 Days', 'Unconfirmed Outside US >30 Days'] as const);
const CF_DETAIL_USER_REASSIGNMENT = (['Related to Complaint', 'Unrelated to Complaint'] as const);
const CF_DETAIL_REQUEST_FOR_RULE_EXCEPTION = (['Switch Pathway Before 13 Weeks', 'Other Rule Exception'] as const);
const CF_DETAIL_FEEDBACK_QUESTIONS_COMPLAINTS = (['Billing/Insurance', 'Hardware/Software', 'Customer Service (Ops teams) Feedback', 'PT Care Delivery Feedback', 'Other Program Feedback or Complaint'] as const);
const CF_REQUEST_TYPE_NEW = (['Care Hub', 'CTT/CTM and Admin Panel', 'ET Personalization Engine (ETPE)', 'Configurator', 'Video Visit/Communications', 'Healthie', 'Manager Requests', 'OS Requests', 'Trial Program (fka VIP) Requests', 'Medical Device Product Feedback', 'Clinical or Adverse Incident'] as const);
const CF_PROGRAM = (['Chronic', 'Acute', 'Multi-indication', 'Surgery', 'Prevention / Wellness', 'Expert Medical Opinion'] as const);
const CF_PATHWAY = (['N/A', 'Ankle', 'Balance', 'Elbow', 'Foot', 'Hand', 'Hip', 'Knee', 'Low Back', 'Neck / Upper Back', 'Pelvic Maternity', 'Pelvic Hypo', 'Pelvic Pain', 'Pelvic Urge', 'Shoulder', 'Wrist', 'Pelvic Health'] as const);
const CF_INTERNAL_REQUESTER_TYPE = (['Coaching: Coach', 'Coaching: Coverage Coach', 'Coaching: HCM', 'Coaching: OS', 'Coaching: OS Manager', 'Clinical: PT', 'Clinical: PT Associate', 'Clinical: PT Manager', 'VIP Program', 'Operations: Logistics', 'Operations: Support Ops', 'Operations: Delivery Ops', 'Education', 'Other'] as const);
const PRIORITY_OPTIONAL = (['Normal- Expect a response within 2 business days or less', 'High- Expect a response within 1 business day or less'] as const);
const CF_OS_OR_VIP_PROGRAM_REQUEST = (['Member technical issue', 'Member non-technical issue'] as const);
const CF_OS_OR_VIP_REQUEST_DETAIL = (['Login', 'ET or Sensors', 'Progress Tracking', 'Enso', 'Other Device Support', 'Other Technical'] as const);
export const FRESHDESK_FIELDS = {
  CUSTOM_DROPDOWN_OPTION: {
    CF_PROGRAM,
    CF_PATHWAY,
    CF_INTERNAL_REQUESTER_TYPE,
    CF_REQUEST_TYPE_NEW,
    PRIORITY_OPTIONAL,
    CF_DIRECT_SUPPORT_PILOT_MANAGERS_PS_REQUEST,
    CF_DETAIL_USER_OUTSIDE_US,
    CF_DETAIL_USER_REASSIGNMENT,
    CF_DETAIL_REQUEST_FOR_RULE_EXCEPTION,
    CF_DETAIL_FEEDBACK_QUESTIONS_COMPLAINTS
  }
};
export type CfInternalRequesterType = typeof CF_INTERNAL_REQUESTER_TYPE[number];
export const MANAGER_ROLES: Array<Partial<CfInternalRequesterType>> = ['Coaching: HCM', 'Clinical: PT Manager'];
export const OS_ROLES: Array<Partial<CfInternalRequesterType>> = ['Coaching: OS', 'Coaching: OS Manager'];
export const VIP_PROGRAM_ROLES: Array<Partial<CfInternalRequesterType>> = ['VIP Program'];
type CfDirectSupportPilotManagersPsRequest = typeof CF_DIRECT_SUPPORT_PILOT_MANAGERS_PS_REQUEST[number];
type CfOsOrVipProgramRequest = typeof CF_OS_OR_VIP_PROGRAM_REQUEST[number];
export type RequestTypeNew = typeof CF_REQUEST_TYPE_NEW[number];
export type RequestTypeMapValue = {
  fieldKey: string;
  label: string;
  options: readonly string[];
};
const CF_DETAIL_FIELD_KEY = 'cf_detail';
const CF_DETAIL_LABEL = 'Request details';
const CF_OS_OR_VIP_REQUEST_DETAIL_FIELD_KEY = 'cf_os_or_vip_request_detail';
const CF_OS_OR_VIP_REQUEST_FIELD_KEY = 'cf_os_or_vip_program_request';
const CF_DIRECT_SUPPORT_PILOT_MANAGERS_PS_REQUEST_FIELD_KEY = 'cf_direct_support_pilot_managers_ps_request';
export type CombinedRequestSubTypeUnion = CfDirectSupportPilotManagersPsRequest | CfOsOrVipProgramRequest;

/* eslint-disable @typescript-eslint/naming-convention */
export const CF_SUB_REQUEST_TYPE_MAP = new Map<CombinedRequestSubTypeUnion, RequestTypeMapValue>([['User Outside US', {
  fieldKey: CF_DETAIL_FIELD_KEY,
  label: CF_DETAIL_LABEL,
  options: CF_DETAIL_USER_OUTSIDE_US
}], ['User Reassignment', {
  fieldKey: CF_DETAIL_FIELD_KEY,
  label: CF_DETAIL_LABEL,
  options: CF_DETAIL_USER_REASSIGNMENT
}], ['Request for Rule Exception', {
  fieldKey: CF_DETAIL_FIELD_KEY,
  label: CF_DETAIL_LABEL,
  options: CF_DETAIL_REQUEST_FOR_RULE_EXCEPTION
}], ['Feedback, Questions, and Complaints', {
  fieldKey: CF_DETAIL_FIELD_KEY,
  label: CF_DETAIL_LABEL,
  options: CF_DETAIL_FEEDBACK_QUESTIONS_COMPLAINTS
}], ['Member technical issue', {
  fieldKey: CF_OS_OR_VIP_REQUEST_DETAIL_FIELD_KEY,
  label: 'Request details',
  options: CF_OS_OR_VIP_REQUEST_DETAIL
}]]);
export const REQUEST_TYPE_MAP = new Map<RequestTypeNew, RequestTypeMapValue>([['Manager Requests', {
  fieldKey: CF_DIRECT_SUPPORT_PILOT_MANAGERS_PS_REQUEST_FIELD_KEY,
  label: 'Select Manager Request type',
  options: CF_DIRECT_SUPPORT_PILOT_MANAGERS_PS_REQUEST
}], ['OS Requests', {
  fieldKey: CF_OS_OR_VIP_REQUEST_FIELD_KEY,
  label: 'Select OS Request type',
  options: CF_OS_OR_VIP_PROGRAM_REQUEST
}], ['Trial Program (fka VIP) Requests', {
  fieldKey: CF_OS_OR_VIP_REQUEST_FIELD_KEY,
  label: 'Select VIP Program Request type',
  options: CF_OS_OR_VIP_PROGRAM_REQUEST
}]]);
/* eslint-enable @typescript-eslint/naming-convention */