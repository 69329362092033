import { HHGrid, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { CustomUserClaim } from '@okta/okta-auth-js';
import { Provider } from 'jotai';
import { useCurrentStoredAdminHook } from '../hooks/use-current-stored-admin';
import { useSse } from '../hooks/use-sse';
import { ShellContextType, ShellProvider } from '../modules/sidebar/contexts/shell.context';
import { Admin } from '../types';
import { OktaRole } from '../types/okta-roles';
import PageContainer from './page-container';
import SideNavigation from './side-navigation';
import SideNavigationDefault from './side-navigation-default';
interface RootScreenProps {
  children?: React.ReactNode;
}
const RootScreen = ({
  children
}: RootScreenProps): JSX.Element => {
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const shellProps: ShellContextType = {
    adminProfile: (currentStoredAdmin as Admin),
    useSse
  };
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const adminRoles = (hingeHealthAuthState?.accessToken?.claims.roles as CustomUserClaim[] | undefined);
  return <Provider>
      <HHGrid container data-testid="root-screen" sx={{
      flexWrap: 'nowrap'
    }}>
        <ShellProvider {...shellProps}>
          <HHGrid item xs="auto">
            {adminRoles && (adminRoles.includes(OktaRole.Coach) || adminRoles.includes(OktaRole.PhysicalTherapist) || adminRoles.includes(OktaRole.NursePractitioner) || adminRoles.includes(OktaRole.OnboardingSpecialist) || adminRoles.includes(OktaRole.OrthopedicSpecialist)) ? <SideNavigation /> : <SideNavigationDefault />}
          </HHGrid>
        </ShellProvider>
        <HHGrid item xs>
          <PageContainer>{children}</PageContainer>
        </HHGrid>
      </HHGrid>
    </Provider>;
};
export default RootScreen;