import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { CoachDashboardFilter } from '../constants/coach-dashboard';
import { OsDashboardFilter } from '../constants/os-dashboard';
import { PtDashboardFilter } from '../constants/pt-dashboard';
import { SORT_PROPERTY } from '../constants/strings/workflows-table';
import { DashboardType, SortWorkflowType, UnreadFilterType } from '../custom-types';
import { CoachWorkflowTypes, CountWorkflowsWithWorkflowTypesQueryResults } from '../types/coach';
import { PtDashboardFilters } from '../types/pt';
export const incompleteResultsData = atom<CountWorkflowsWithWorkflowTypesQueryResults[] | undefined>(undefined);
export const workflowListLoading = atom(false);
export const selectedWorkflowType = atom<CoachWorkflowTypes | undefined>(undefined);
export const selectedDashboardType = atom<DashboardType>(DashboardType.Coach);
export const selectedUnreadType = atom<UnreadFilterType | null>(UnreadFilterType.AllTypes);
export const selectedCoachDashboardFilters = atom<typeof CoachDashboardFilter[keyof typeof CoachDashboardFilter]>(CoachDashboardFilter.All);
export const totalAllTypesCount = atom<number>(0);
export const totalUnreadCount = atom<number>(0);
export const totalModularIamCount = atom<number>(0);
export const totalAllTypesCountLowPriority = atom<number>(0);
export const totalUnreadCountLowPriority = atom<number>(0);
export const totalModularIamCountLowPriority = atom<number>(0);
export const selectedPtDashboardFilters = atom<typeof PtDashboardFilter[keyof typeof PtDashboardFilter]>(PtDashboardFilter[PtDashboardFilters.VideoVisitUpcoming]);
export const selectedOsDashboardFilters = atom<typeof OsDashboardFilter[keyof typeof OsDashboardFilter]>(OsDashboardFilter.All);
export const iframeLink = atomWithReset('');
export const showErrorAlertAtom = atom<boolean>(false);
export const sortWorkflowsAtom = atom<SortWorkflowType>({
  property: SORT_PROPERTY.LATEST_TASK_DATE,
  order: 'ASC'
});
export const healthLogNotesSplitFlag = atom<boolean>(false);
export const unreadMessagesMlSplitFlag = atom<boolean>(false);
export const showMarkCompleteAlert = atom<boolean>(false);
export const markCompleteAlertType = atom<'success' | 'error' | 'info' | 'warning'>('info');
export const markCompleteAlertMessage = atom<string>('');
export const selectAllWorkflowAtom = atom<boolean>(false);
export const listToMarkWorkflowComplete = atom<number[]>([]);
export const totalWorkflowCount = atom<number>(0);
export const listToMarkWorkflowPending = atom<number[]>([]);
export interface BulkState {
  HLN: 'completed' | 'fail' | 'progress' | null;
  Unread: 'completed' | 'fail' | 'progress' | null;
}
export interface LowPriorityBulkState {
  HLN: number;
  Unread: number;
}
export const bulkWorkflowStateAtom = atom<BulkState>({
  HLN: null,
  Unread: null
});
export const bulkWorkflowHLNProgress = atom<boolean>(false);
export const bulkWorkflowUnreadProgress = atom<boolean>(false);
export const bulkShowHLNSuccessAlert = atom<boolean>(false);
export const bulkShowUnreadSuccessAlert = atom<boolean>(false);
export const bulkShowHLNFailedAlert = atom<boolean>(false);
export const bulkShowUnreadFailedAlert = atom<boolean>(false);
export const workflowIdsSuccessfullyTransitioned = atom<LowPriorityBulkState>({
  HLN: 0,
  Unread: 0
});
export const workflowIdsFailedTransition = atom<LowPriorityBulkState>({
  HLN: 0,
  Unread: 0
});
export const showTimeoutAlert = atom<boolean>(false);
export const markedWorkflowIds = atom<number[]>([]);
export const dashboardFilterPrevState = atom<typeof CoachDashboardFilter[keyof typeof CoachDashboardFilter] | typeof PtDashboardFilter[keyof typeof PtDashboardFilter] | typeof OsDashboardFilter[keyof typeof OsDashboardFilter]>(CoachDashboardFilter.All);
export const selectAllPTWorkflowAtom = atom<boolean>(false);
export const changePT = atom<boolean>(false);
export const ptPooledNewDashboardID = atom<string | null>('');
export const showAssignedAlert = atom<boolean>(false);
export const listOfWorkflowsObject = atom<{
  id: number;
  name: string;
  workflowType: string;
}[]>([]);
export const listOfIncompletedWorkflowsObject = atom<{
  id: number;
  name: string;
  workflowType: string;
}[]>([]);
export const assignedWorkflowsState = atom<{
  totalAssignedCount: number;
  assignedMessage: string;
  assignedType: 'success' | 'error' | 'info' | 'warning';
}>({
  totalAssignedCount: 0,
  assignedMessage: '',
  assignedType: 'info'
});
export const shouldRefetchWorkflows = atom<boolean>(false);
export const currentSelectedWorkflowTypeAtom = atom<string>('');
export const videoTabchanged = atom<boolean>(false);
export const timeoutIdAtom = atom<boolean>(false);