import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { SseEvents } from '../constants/strings/events';
import { GQL_ERROR_POLICY_ALL, GQL_FETCH_POLICY_CACHE_FIRST } from '../constants/strings/gql';
import { NAV_SPLIT_TREATMENTS } from '../constants/strings/split';
import { WorkflowTransitionedSseData } from '../contexts/sse.context';
import { CoachWorkflowTypes, commonCoachCompletedStates, CommonCoachState, QueueCategoryType } from '../custom-types';
import { videoVisitFollowupStates, VideoVisitsState } from '../modules/acute-physical-therapy/custom-types';
import { commonPtCompletedStates, commonPtExcludedStates } from '../modules/dashboard/types/pt';
import { getEndOfDay, getStartOfDay } from '../modules/dashboard/utils/get-formatted-date';
import { ON } from '../modules/sidebar/constants/splits';
import { commonExcludedStates } from '../modules/sidebar/custom-types';
import { currentAdminUuid } from '../state/queue-atoms';
import { CountQueueWorkflowsWithWorkflowTypesQuery, CountQueueWorkflowsWithWorkflowTypesQueryVariables, CountWorkflowsFiltersInputDto, useCountQueueWorkflowsWithWorkflowTypesLazyQuery, WorkflowSubtotals } from '../types';
import { OktaRole } from '../types/okta-roles';
import { getWorkflowSseData } from '../utils/coach-sse';
import { defaultErrorHandler } from '../utils/errors';
import { useCurrentStoredAdminHook } from './use-current-stored-admin';
import { useSse } from './use-sse';
type RefetchQueryType = ((variables?: Partial<CountQueueWorkflowsWithWorkflowTypesQueryVariables>) => Promise<ApolloQueryResult<CountQueueWorkflowsWithWorkflowTypesQuery>>) | undefined;
export const useGetQueueCountsCommonHook = (workflowTypesVariable: string[], countWorkflowsFiltersVariables: Partial<{
  countWorkflowsFilters: Partial<CountWorkflowsFiltersInputDto>;
}>, queueCategoryType?: QueueCategoryType | null, useStartAndEndDate?: boolean): {
  totalCount: string;
  subTotalCounts?: WorkflowSubtotals[] | null;
  errorInCountFetch: boolean;
  loading: boolean;
  refetch: RefetchQueryType;
  subscribedSseData: unknown;
  workflowsQueueSplitOn: boolean;
  setCountWorkflowFilter?: React.Dispatch<React.SetStateAction<CountWorkflowsFiltersInputDto>>;
  updateWorkflowCounts: ({
    subscribedSseData
  }: {
    subscribedSseData: unknown;
  }) => void;
} => {
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const [countWorkflowFiltersInitialInput, setCountWorkflowFilter] = useState<CountWorkflowsFiltersInputDto>({
    excludeStates: commonExcludedStates,
    workflowTypes: [CoachWorkflowTypes.UnreadMessages]
  });
  const [getQueueCounts, {
    data,
    error,
    loading,
    refetch
  }] = useCountQueueWorkflowsWithWorkflowTypesLazyQuery({
    variables: {
      countWorkflowsFilters: countWorkflowFiltersInitialInput // Here we are using the state due to the bug descibed above
    }
  });
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const roles = (hingeHealthAuthState?.accessToken?.claims?.roles as OktaRole[]);
  const isCoach = useMemo(() => roles?.includes(OktaRole.Coach), [roles]);
  const isOS = useMemo(() => roles?.includes(OktaRole.OnboardingSpecialist), [roles]);
  const isCoachOrOs = isCoach || isOS;
  const [totalCount, setTotalCount] = useState('');
  const [countsLoading, setCountsLoading] = useState(true);
  const [errorInCountFetch, setErrorInCountFetch] = useState(false);
  const [subTotalCounts, setSubtotalCounts] = useState<WorkflowSubtotals[] | undefined | null>(undefined);
  const [, setCurrentAdminUuid] = useAtom(currentAdminUuid);
  const splitClient = useClient();
  const workflowsQueueSplitOn = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.WORKFLOWS_QUEUE, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON;
  const enableWorkflowStateFilteringTreatment = splitClient?.getTreatment(NAV_SPLIT_TREATMENTS.ENABLE_WORKFLOW_STATE_FILTERING, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON;
  const sseEvents = [SseEvents.WorkflowCreated, SseEvents.WorkflowTransitioned, SseEvents.TaskUpdated];
  const subscribedSseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, sseEvents, {
    replayLastEvent: true
  });
  const queueFetchErrorHandler = (error_0?: ApolloError, dataErrors?: {
    error: string;
  }[]): void => {
    if (error_0 || dataErrors && dataErrors.length > 0) {
      setErrorInCountFetch(true);
      setCountsLoading(false);
      if (error_0?.message) defaultErrorHandler(error_0.message);
      if (dataErrors && dataErrors.length > 0) defaultErrorHandler(dataErrors[0].error);
    }
  };
  const updateWorkflowCounts = ({
    subscribedSseData: subscribedSseData_0
  }: {
    subscribedSseData: unknown;
  }): void => {
    const workflow = getWorkflowSseData(subscribedSseData_0);
    let updatedSubTotalCounts: WorkflowSubtotals[] = [];
    if (subTotalCounts && [...commonCoachCompletedStates, ...commonPtCompletedStates,
    // Archive is a passive state, hence should not contribute to incomplete workflows nor complete workflows.
    // When member alert reminder workflow moves from open to archive, it moves from a considered state to this passive state
    // Hence, decrement the incomplete count
    // At the same time, archive is not a complete state hence complete count should not increment.
    CommonCoachState.CoachArchive].includes(workflow.stateName) && workflow.type === SseEvents.WorkflowTransitioned &&
    // We fetch workflows of members the logged in admin covers for.
    // Logged in admin can resolve workflows of other members (PT pooled dashboard, not under their cov) and receive SSE for same.
    // As long as the logged in admin is part of the care team, we update the count.
    ((workflow as unknown) as WorkflowTransitionedSseData)?.careTeamUuids?.includes((currentStoredAdmin?.uuid as string))) {
      // Iterate over the subTotal array
      // match the workflowType and update the count
      updatedSubTotalCounts = subTotalCounts.map(item => {
        // If count is there then assign it otherwise zero
        const count = item.count ?? 0;
        // Match the workflowType and check count is more than 0
        if (workflow.serviceName === item.workflowType && count) return {
          ...item,
          count: count - 1
        };
        return item;
      });
      setSubtotalCounts(updatedSubTotalCounts);
    } else if (workflow.type === SseEvents.WorkflowCreated && subTotalCounts) {
      // This is to updates the counts when new workflow is created
      updatedSubTotalCounts = subTotalCounts.map(item_0 => {
        // Match the workflow and increment the count
        if (workflow.serviceName === item_0.workflowType) {
          const count_0 = item_0.count ?? 0;
          return {
            ...item_0,
            count: count_0 + 1
          };
        }
        return item_0;
      });
      setSubtotalCounts(updatedSubTotalCounts);
    }
  };
  useEffect(() => {
    const filters = countWorkflowsFiltersVariables.countWorkflowsFilters;
    const dataFetchCounts = async (currentAdminUuid: string): Promise<void> => {
      const commonFilters = {
        adminUuids: [currentAdminUuid],
        workflowTypes: workflowTypesVariable
      };
      const {
        includeOnlyCompletedStates,
        excludeAllCompletedStates,
        excludeAllPassiveStates
      } = filters || {};
      let excludeStates;
      if (queueCategoryType === QueueCategoryType.Encounter) {
        if (enableWorkflowStateFilteringTreatment) {
          excludeStates = [...videoVisitFollowupStates];
        } else {
          excludeStates = [...commonPtExcludedStates, ...videoVisitFollowupStates];
        }
      } else if (queueCategoryType === QueueCategoryType.Workflow) {
        if (enableWorkflowStateFilteringTreatment) {
          excludeStates = [VideoVisitsState.CompleteVideoVisit];
        } else {
          excludeStates = [...commonPtExcludedStates, VideoVisitsState.CompleteVideoVisit];
        }
      } else {
        if (!enableWorkflowStateFilteringTreatment) {
          excludeStates = commonPtExcludedStates;
        }
      }
      const ptFilters = {
        ...(useStartAndEndDate ? {
          completedAfterDate: getStartOfDay(),
          completedBeforeDate: getEndOfDay()
        } : {
          dueTaskEndDate: undefined,
          excludeStates
        }),
        useCoverage: false,
        ...(!enableWorkflowStateFilteringTreatment && {
          completedStates: countWorkflowsFiltersVariables.countWorkflowsFilters?.completedStates
        }),
        ...(enableWorkflowStateFilteringTreatment ? {
          ...(includeOnlyCompletedStates && {
            includeOnlyCompletedStates
          }),
          ...(excludeAllCompletedStates && {
            excludeAllCompletedStates
          }),
          ...(excludeAllPassiveStates && {
            excludeAllPassiveStates
          })
        } : {})
      };
      const variables = {
        countWorkflowsFilters: isCoachOrOs ? {
          ...commonFilters,
          ...filters
        } : {
          ...commonFilters,
          ...ptFilters
        }
      };
      await getQueueCounts({
        variables,
        errorPolicy: GQL_ERROR_POLICY_ALL,
        returnPartialData: true,
        fetchPolicy: GQL_FETCH_POLICY_CACHE_FIRST,
        notifyOnNetworkStatusChange: true
      });
    };
    if (currentStoredAdmin?.uuid && workflowsQueueSplitOn) {
      setCurrentAdminUuid(currentStoredAdmin.uuid);
      dataFetchCounts(currentStoredAdmin.uuid);
      setCountsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoredAdmin?.uuid, workflowTypesVariable, workflowsQueueSplitOn, getQueueCounts, isCoachOrOs]);
  useEffect(() => {
    const totalCountFromData = data?.countWorkflowsWithWorkflowTypes.total ?? 0;
    setTotalCount(totalCountFromData.toString());
    if (data?.countWorkflowsWithWorkflowTypes.subtotals) {
      setSubtotalCounts(data?.countWorkflowsWithWorkflowTypes.subtotals);
    }
    if (error || data?.countWorkflowsWithWorkflowTypes.errors) {
      queueFetchErrorHandler(error, data?.countWorkflowsWithWorkflowTypes.errors);
    }
  }, [data, error]);
  return {
    totalCount,
    subTotalCounts,
    errorInCountFetch,
    loading: countsLoading || loading,
    refetch,
    subscribedSseData,
    workflowsQueueSplitOn,
    setCountWorkflowFilter,
    updateWorkflowCounts
  };
};