export const SPLIT_TREATMENTS = {
  UNREAD_MESSAGE_ML: 'unread-message-ml',
  HEALTH_LOG_NOTES: 'member-health-log',
  PT_POOLED_DASHBOARD: 'pt-pooled-dashboard',
  PT_ACTION_PLAN_REVIEW: 'pt-action-plan-review-workflow',
  SETUP_ACTION_PLAN_WORKFLOW: 'setup-action-plan-workflow',
  COACH_WEEKLY_GOAL_EXPIRY: 'coach-weekly-goal-expiry-workflow',
  COACH_VIDEO_VISIT: 'coach-video-visit',
  ENABLE_WORKFLOW_STATE_FILTERING: 'enable-workflow-state-filtering'
};
export const ON = 'on';
export const OFF = 'off';